import {ContentType} from "@components/CustomTypography/Content/Content"
import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import Title from "@components/CustomTypography/Title/Title"
import React from "react"

import styles from "./CountsCard.module.scss"

type Props = {
	className?: string
} & ContentType

const CountsCard = (props: Props) => {
	return (
		<div className={`${styles.container} ${props.className}`}>
			<Title
				className={styles.title}
				content={props.data?.title}
				size={props.size}
			/>
			<Paragraph
				content={props.data?.subtitle}
				fontWeight={600}
				size={props.size}
			/>
		</div>
	)
}

export default CountsCard
