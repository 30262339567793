import FullWidthHeaderSimple from "@components/BasicComponents/FullWidthHeader/FullWidthHeaderSimple"
import CompanyProductSlider from "@components/Pages/Company/CompanyProductSlider/CompanyProductSlider"
import HomeBookSection from "@components/Pages/Home/HomeBookSection/HomeBookSection"
import HomeCustomersSection from "@components/Pages/Home/HomeCustomersSection/HomeCustomersSection"
import HomeInformationSection from "@components/Pages/Home/HomeInformationSection/HomeInformationSection"
import HomeNewsSection from "@components/Pages/Home/HomeNewsSection/HomeNewsSection"
import HomeSecuritySection from "@components/Pages/Home/HomeSecuritySection/HomeSecuritySection"
import HeadMeta from "@components/Theme/HeadMeta/HeadMeta"
import {getPosts} from "@config/modules"
import getValueOrThrow from "@helpers/getValueOrError"
import {getTranslatedPageData} from "@hooks/PagesData/getTranslatedPageData"
import BlogPosts from "@jsons/blogPosts.json"
import CustomerStories from "@jsons/customerStories.json"
import Logos from "@jsons/customerStoriesLogos.json"
import fronPageData from "@jsons/frontPage.json"

export default function Home(): JSX.Element {
	const pageData = getTranslatedPageData(fronPageData, undefined, true)
	const list = getValueOrThrow(pageData.AdvancedSideAsset)
	const stories = getPosts("CustomerStoriesModule", {data: CustomerStories})
	const logos = getPosts("logosModule", {data: Logos})
	const Posts = getPosts("BlogPostsModule", {data: BlogPosts})

	return (
		<>
			<HeadMeta meta={pageData.metaData} />
			<FullWidthHeaderSimple data={getValueOrThrow(pageData.heroData)} />
			<CompanyProductSlider data={list[0]} />
			<HomeSecuritySection data={list[1]} />
			<HomeCustomersSection
				data={list[2]}
				logos={logos}
				stories={stories}
			/>
			<HomeInformationSection counts={list[6]} data={list[3]} />
			<HomeNewsSection data={list[4]} lastPost={Posts} />
			<HomeBookSection data={list[5]} />
		</>
	)
}
