import ContactUsForm from "@components/BasicComponents/Forms/ContactUsForm/ContactUsForm"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Description from "@components/CustomTypography/Description/Description"
import Overlay from "@components/CustomTypography/Overlay/Overlay"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import {AdvancedSideAssetModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./HomeBookSection.module.scss"

type Props = {
	data: AdvancedSideAssetModuleType
}

const HomeBookSection = (props: Props) => {
	return (
		<Separator
			id={"book-a-demo"}
			size={128}
			style={{
				color: "white",
				position: "relative",
				background:
					"url(https://dreambroker.com/dreamBroker/footerBGSmall_2024-11-01-085050.jpg)",
				backgroundSize: "cover",
			}}
			type={"padding"}
		>
			<ContainerWrapper>
				<div className={styles.mainWrapper}>
					<div className={styles.contentBox}>
						<Title
							content={props.data.sectionTitle}
							fontWeight={600}
							size={"XL"}
							type={"2"}
						/>
						<Separator size={16} />
						<Description
							content={props.data.sectionSubtitle}
							size={"XL"}
						/>
					</div>
					<div className={styles.formBox}>
						<Separator customPadding={"25px"}>
							<ContactUsForm
								container={{
									style: {
										color: "black",
									},
								}}
							/>
						</Separator>
					</div>
				</div>
			</ContainerWrapper>
			<Overlay opacity={"0.4"} />
		</Separator>
	)
}

export default HomeBookSection
