import Asset from "@components/CustomTypography/Asset/Asset"
import React, {useState} from "react"

import styles from "./ContentAsset.module.scss"
import ContentAssetExpandedContent from "./ContentAssetExpandedContent"
import {ContentAssetExpandedType} from "./ContentAssetExpandedTypes"

const ContentAssetExpanded = (props: ContentAssetExpandedType): any => {
	// Functionality for expanded list items
	const [showItem, setShowItem] = useState<number>(0)
	const changeShowedItem = (id: any): void => {
		setShowItem(Number(id.target.id))
	}
	const assetData = {...props.mainData.mainContent?.[showItem]?.preview}

	const directionMap = {
		right: styles.right,
		left: styles.left,
	}

	const direction = directionMap[props.direction || "right"] || styles.right

	return (
		<div className={`${styles.assetContainer} ${direction}`}>
			<div className={styles.content}>
				<ContentAssetExpandedContent
					data={props.mainData}
					direction={props.direction}
					handleClick={changeShowedItem}
					id={showItem}
				/>
			</div>
			<div className={styles.asset}>
				{assetData && (
					<Asset
						data={{
							...assetData,
						}}
						type={assetData.type ? assetData.type : "string"}
					/>
				)}
			</div>
		</div>
	)
}

export default ContentAssetExpanded
