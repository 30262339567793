import CountsCard from "@components/BasicComponents/Cards/CountsCard/CountsCard"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import ContentAssetSwitcher from "@components/Switchers/ContentAssetSwitcher/ContentAssetSwitcher"
import {
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./HomeInformationSection.module.scss"

type Props = {
	data: AdvancedSideAssetModuleType
	counts: AdvancedSideAssetModuleType
}

const HomeInformationSection = (props: Props) => {
	return (
		<Separator backgroundColor={"#f5f5f5"} size={128}>
			<ContainerWrapper>
				<ContentAssetSwitcher
					contentAssetProps={{
						contentProps: {
							titleProps: {
								withoutHtml: false,
								fontWeight: 400,
							},
						},
					}}
					data={props.data}
					direction={"left"}
				/>
				<Separator size={128} />
				<div className={styles.container}>
					{props.counts.mainContent?.map(
						(
							element: AdvancedSideAssetMainContent,
							index: number,
						) => {
							return (
								<CountsCard
									key={index}
									className={styles.countBox}
									data={element}
									size={"M"}
								/>
							)
						},
					)}
				</div>
			</ContainerWrapper>
		</Separator>
	)
}

export default HomeInformationSection
