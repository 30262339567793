import Content, {
	ContentType,
} from "@components/CustomTypography/Content/Content"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import ButtonSwitcher from "@components/Switchers/ButtonSwitcher/ButtonSwitcher"
import React from "react"

import styles from "./ImageTextCard.module.scss"

export type ImageTextCardType = {
	className?: string
	buttonClassName?: string
	shortDesc?: number
	withCenterBtn?: boolean
	scale?: {
		width: 4 | 16
		height: 3 | 9
	}
} & ContentType & {imageWidth?: number; imageHeight?: number}

const ImageTextCard = (props: ImageTextCardType) => {
	return (
		<div className={`${styles.assetContainer} ${props.className}`}>
			{props.data?.preview && (
				<NewResponsiveImage
					style={{width: props.imageWidth, height: props.imageHeight}}
					{...props.data?.preview}
				/>
			)}
			<div style={{paddingTop: "16px"}}>
				<Content
					contentProps={props.contentProps}
					data={{
						title: props.data?.title,
						subtitle: props.data?.subtitle,
						content: props.data?.content,
					}}
					shortDesc={props.shortDesc}
					size={props.size || "S"}
					subtitleProps={props.subtitleProps}
					titleProps={props.titleProps}
				/>
			</div>
			{props.data?.buttons != null && (
				<div
					className={`${
						props.withCenterBtn ? styles.buttonBox : ""
					} ${props.buttonClassName}`}
				>
					<ButtonSwitcher buttonsList={props.data} />
				</div>
			)}
		</div>
	)
}

export default ImageTextCard
