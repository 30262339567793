import BlogCardHorizontal from "@components/BasicComponents/Cards/BlogCard/BlogCardHorizontal"
import BlogVerticalCard from "@components/BasicComponents/Cards/BlogCard/BlogVerticalCard"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import ButtonComponent from "@components/Theme/Button/Button"
import {
	AdvancedSideAssetModuleType,
	BlogPostModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./HomeNewsSection.module.scss"

type Props = {
	data: AdvancedSideAssetModuleType
	lastPost: BlogPostModuleType[]
}

const HomeNewsSection = (props: Props) => {
	return (
		<Separator size={128}>
			<ContainerWrapper>
				<Title content={props.data.sectionTitle} center />
				<Separator />
				<BlogVerticalCard data={props.lastPost[0]} direction={"left"} />
				<Separator />
				<div className={styles.cardContainer}>
					{props.lastPost.map(
						(element: BlogPostModuleType, index: number) => {
							return (
								<React.Fragment key={index}>
									{index > 0 && index < 4 && (
										<BlogCardHorizontal
											className={styles.cardBox}
											data={element}
										/>
									)}
								</React.Fragment>
							)
						},
					)}
				</div>
				<Separator />
				<div className={styles.bottomButton}>
					<ButtonComponent
						buttonType={"secondary"}
						link={props.data.sectionBottomButtonLink}
						asLink
					>
						{props.data.sectionBottomButton}
					</ButtonComponent>
				</div>
			</ContainerWrapper>
		</Separator>
	)
}

export default HomeNewsSection
