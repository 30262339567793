import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import {Translate} from "@components/Theme/Translate/Translate"
import {BlogPostModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import Link from "next/link"
import React from "react"

import styles from "./BlogCardHorizontal.module.scss"

type Props = {
	data: BlogPostModuleType
	className?: string
}

const BlogCardHorizontal = (props: Props): JSX.Element => {
	return (
		<Link
			className={`${styles.cardContainer} ${props.className}`}
			href={props.data.link}
			rel={"noreferrer"}
		>
			<NewResponsiveImage
				{...props.data.thumbui}
				containerClassName={styles.imageContainer}
				imageClassName={styles.image}
			/>

			<div className={styles.contentContainer}>
				<Paragraph
					className={styles.readMore}
					content={props.data.title}
					fontWeight={600}
					size={"XS"}
				/>

				<div className={styles.buttonContainer}>
					<Paragraph
						content={
							<>
								{" "}
								<Translate capitalizeFirst>Posted on</Translate>
								: {props.data.upperTitle}
							</>
						}
						fontWeight={400}
						size={"XS"}
						withoutPadding
					/>
					{/* <ButtonComponent buttonType={"readMore"} asLink>
						<Translate>Read More</Translate>
					</ButtonComponent> */}
				</div>
			</div>
		</Link>
	)
}

export default BlogCardHorizontal
