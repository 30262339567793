import Description from "@components/CustomTypography/Description/Description"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import {SeparatorType} from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import ButtonComponent from "@components/Theme/Button/Button"
import {Translate} from "@components/Theme/Translate/Translate"
import {
	CategoryModuleType,
	CustomerStoryModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import Link from "next/link"
import React from "react"

import CardsCategoryButton from "../CardsCategoryButton/CardsCategoryButton"

import styles from "./CustomerStoryHorizontal.module.scss"

type Props = {
	data: CustomerStoryModuleType
	container?: SeparatorType
	className?: string
}

const CustomerStoryHorizontal = (props: Props) => {
	const cardContainer = `${styles.cardContainer} ${props.className}`

	const preview = props.data.preview.filename
		? props.data.preview
		: props.data.logo
	return (
		<Link
			className={cardContainer}
			data-testid={"CustomerStoryHorizontalContainer"}
			href={props.data.link}
			rel={"noreferrer"}
		>
			<div className={styles.assetContainer}>
				<NewResponsiveImage
					{...preview}
					containerClassName={styles.imageContainer}
					imageClassName={styles.image}
				/>
			</div>

			<div className={styles.contentContainer}>
				<div className={styles.upperTitle}>
					<Paragraph
						content={props.data.company}
						fontWeight={600}
						size={"S"}
					/>

					<Paragraph
						content={
							<>
								{props.data.useCase.map(
									(
										element: CategoryModuleType,
										index: number,
									) => {
										return (
											<CardsCategoryButton
												key={index}
												data={element}
												link={"customerStories"}
												modifier={"useCases"}
											/>
										)
									},
								)}
							</>
						}
						fontWeight={400}
						size={"XS"}
					/>
				</div>

				<Title
					content={props.data.title}
					fontWeight={600}
					size={"S"}
					type={"2"}
				/>
				<div className={styles.bigDesc}>
					<Description
						content={props.data.content}
						shortDesc={200}
						size={"M"}
					/>
					<object>
						<ButtonComponent buttonType={"readMore"} asLink>
							<Translate> Read More</Translate>
						</ButtonComponent>
					</object>
				</div>
			</div>
			<div className={styles.smallDesc}>
				<Description
					content={props.data.content}
					shortDesc={200}
					size={"M"}
				/>
				<object>
					<ButtonComponent buttonType={"readMore"} asLink>
						<Translate>Read More</Translate>
					</ButtonComponent>
				</object>
			</div>
		</Link>
	)
}

export default CustomerStoryHorizontal
