import ContentAssetNew, {
	ContentAssetNewProps,
} from "@components/BasicComponents/ContentAsset/ContentAsset"
import ContentAssetExpanded from "@components/BasicComponents/ContentAsset/Expanded/ContentAssetExpanded"
import Separator from "@components/CustomTypography/Separator/Separator"
import {direction} from "@customTypes/customTypes"
import {
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {calculateDirection} from "@hooks/calculateDirection"
import React from "react"

type Props = {
	data?: AdvancedSideAssetModuleType
	withAutoSizing?: boolean
	direction: direction
	contentAssetProps?: ContentAssetNewProps
	shortDesc?: number
	separatorSize?: 8 | 16 | 32 | 64 | 128 | 256
}

const ContentAssetSwitcher = (props: Props) => {
	if (typeof props.data === "undefined") {
		return <></>
	}

	const mainContent = props.data.mainContent

	if (props.data.expanded) {
		return (
			<ContentAssetExpanded
				mainData={props.data}
				{...props.contentAssetProps}
				direction={props.direction}
			/>
		)
	}

	return (
		<>
			{mainContent?.map(
				(element: AdvancedSideAssetMainContent, index: number) => {
					return (
						<React.Fragment key={index}>
							<ContentAssetNew
								{...props.contentAssetProps}
								data={element}
								direction={calculateDirection(
									props.direction,
									index,
								)}
								shortDesc={props.shortDesc}
							/>
							{mainContent?.length > index + 1 && (
								<Separator
									size={
										props.separatorSize
											? props.separatorSize
											: 64
									}
								/>
							)}
						</React.Fragment>
					)
				},
			)}
		</>
	)
}

export default ContentAssetSwitcher
