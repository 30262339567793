import ContentAsset from "@components/BasicComponents/ContentAsset/ContentAsset"
import MySwiper from "@components/BasicComponents/Swiper/MySwiper"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import {
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"
import {SwiperSlide} from "swiper/react"

type Props = {
	data: AdvancedSideAssetModuleType
}

const CompanyProductSlider = (props: Props) => {
	const mainContent = props.data.mainContent

	return (
		<section>
			<ContainerWrapper style={{overflow: "hidden"}}>
				<Separator size={128}>
					<Title
						content={props.data.sectionTitle}
						fontWeight={600}
						size={"XL"}
						type={"2"}
						center
					/>
					{/* Career Stories Card List */}
					<MySwiper
						loop={false}
						module={mainContent?.map(
							(
								element: AdvancedSideAssetMainContent,
								index: number,
							) => {
								return (
									<SwiperSlide
										key={`slideInitialSection${index}`}
									>
										<Separator
											withoutMeasure={
												!props.data?.sectionTitle
											}
										/>
										<ContentAsset
											contentProps={{
												size: "L",
												titleProps: {
													capitalize: false,
												},
											}}
											data={element}
											direction={"right"}
										/>
										<Separator />
									</SwiperSlide>
								)
							},
						)}
					/>
				</Separator>
			</ContainerWrapper>
		</section>
	)
}

export default CompanyProductSlider
