import React from "react"
import {Autoplay, Navigation, Pagination} from "swiper"
import {Swiper} from "swiper/react"
import {SwiperModule} from "swiper/types"
import "swiper/swiper-bundle.css"

type SwiperProps = {
	module?: React.ReactNode
	className?: string
	pagination?: {
		clickable?: boolean
	}
	breakpoints?: {[key: string]: {slidesPerView: number}}
	slidesPerView?: number
	spaceBetween?: number
	loop?: boolean
	navigation?: boolean
	modules?: SwiperModule[] | undefined
	autoplay?: {
		delay: number
		disableOnInteraction?: boolean
		pauseOnMouseEnter?: boolean
	}
}

const MySwiper = (props: SwiperProps) => {
	const {
		module = null,
		className = "mySwiper",
		modules = [Pagination, Navigation, Autoplay],
		pagination = {
			clickable: true,
		},
		slidesPerView = 1,
		spaceBetween = 300,
		loop = true,
		navigation = true,
	} = props

	return (
		<Swiper
			autoplay={props.autoplay}
			breakpoints={props.breakpoints}
			className={className}
			loop={loop}
			modules={modules}
			navigation={navigation}
			pagination={pagination}
			slidesPerView={slidesPerView}
			spaceBetween={spaceBetween}
		>
			{module}
		</Swiper>
	)
}

export default MySwiper
