import {HeroModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./FullWidthHeaderSimple.module.scss"

const FullWidthHeaderSimple = (props: {data: HeroModuleType}) => {
	return (
		<div className={styles.container}>
			<video
				className={`${styles.videoBackground} ${
					props.data.mobileAsset ? styles.withoutMobile : ""
				}`}
				preload={"auto"}
				autoPlay
				loop
				muted
				playsInline
			>
				<source src={props.data.desktopAsset?.src} type={"video/mp4"} />
			</video>
			{props.data.mobileAsset && (
				<video
					className={`${styles.videoBackground} ${styles.mobile}`}
					preload={"auto"}
					autoPlay
					loop
					muted
					playsInline
				>
					<source
						src={props.data.mobileAsset.src}
						type={"video/mp4"}
					/>
				</video>
			)}
		</div>
	)
}

export default FullWidthHeaderSimple
