import {direction} from "@customTypes/customTypes"

export const calculateDirection = (
	direction: "right" | "left",
	index: number,
): direction => {
	const isRightDirection = direction === "right"
	const isOddIndex = index % 2 === 1

	if (
		(isRightDirection && isOddIndex) ||
		(!isRightDirection && !isOddIndex)
	) {
		return "right"
	} else {
		return "left"
	}
}
