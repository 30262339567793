import Description from "@components/CustomTypography/Description/Description"
import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import Title from "@components/CustomTypography/Title/Title"
import ButtonSwitcher from "@components/Switchers/ButtonSwitcher/ButtonSwitcher"
import ButtonComponent from "@components/Theme/Button/Button"
import {AdvancedSideAssetMainContent} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React, {useRef} from "react"
import {CSSTransition, TransitionGroup} from "react-transition-group"

import styles from "./ContentAsset.module.scss"
import {ContentAssetExpandedContentType} from "./ContentAssetExpandedTypes"

const ContentAssetExpandedContent = (
	props: ContentAssetExpandedContentType,
): JSX.Element => {
	// Reference for animation of expanded list item
	const nodeRef = useRef(null)

	return (
		<>
			{/* Title on the List Items */}
			<Title content={props.data?.sectionTitle} size={"M"} type={"2"} />
			<ul>
				{props.data?.mainContent?.map(
					(element: AdvancedSideAssetMainContent, index: number) => {
						return (
							<li key={index} className={styles.list__item}>
								<>
									{/* Button with title for expanding of List Item */}
									<ButtonComponent
										buttonType={"unstyled"}
										expandActive={props.id === index}
										expandUnactive={props.id !== index}
										size={"default"}
										onClick={props.handleClick}
									>
										<Paragraph
											className={styles.listItem}
											content={element.title}
											fontWeight={600}
											id={String(index)}
											size={"M"}
											capitalize
											withoutHtml
										/>
									</ButtonComponent>
									{/* Animated List Item content body */}
									<TransitionGroup>
										<>
											{props.id === index && (
												<CSSTransition
													classNames={{
														enter: styles.listEnter,
														enterActive:
															styles.listEnterActive,
														exit: styles.listExit,
														exitActive:
															styles.listExitActive,
													}}
													in={props.id === index}
													nodeRef={nodeRef}
													timeout={500}
												>
													<div ref={nodeRef}>
														<Paragraph
															content={
																element.subtitle
															}
															size={"S"}
														/>
														<Description
															content={
																element.content
															}
															size={"L"}
														/>
														{/* Defining the right Button Type, Link or Popup Button */}
														{element.buttons !=
															null && (
															<div
																style={{
																	display:
																		"flex",
																}}
															>
																<ButtonSwitcher
																	buttonsList={
																		element
																	}
																/>
															</div>
														)}
													</div>
												</CSSTransition>
											)}
										</>
									</TransitionGroup>
								</>
							</li>
						)
					},
				)}
			</ul>
		</>
	)
}

export default ContentAssetExpandedContent
